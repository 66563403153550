<template>
    <div class="register-body d-flex flex-column align-items-center justify-content-around h-80">
        <a href="/">
            <svg class="mb-2 mt-5" height="85" width="122">
                <use xlink:href="#symbol-logo-darker"></use>
            </svg>
        </a>

        <template>
            <div class="build">
                <h2 class="auth-text text-center">We’re building your platform…</h2>
                <p class="text-center build-title">This shouldn't take long</p>
                <p class="text-center build-title">We'll shoot you an email when we are done.</p>
            </div>

            <div class="bg-transparent step step-4">
                <div class="bg-transparent w-100 h-100">
                    <lottie-animation :path="asset('lottie/MyKii_LoadingKeyAnimation_LottieFile.json')" class="lottie"/>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import LottieAnimation from "./LottieAnimation.vue";

export default {
	components: {
		LottieAnimation
	},

	props: {
		domain: {
			required: true
		},

		partner: {
			required: true
		}
	},

	data: function () {
		return {
			//
		}
	},

	mounted: function () {
		this.initEcho(this.partner)
	},

	methods: {
		initEcho: function (partner) {
			Echo.channel(`partner-registration.${partner.id}`).listen('.partnerRegistrationComplete', (message) => {
				if (message.partner.id === partner.id) {
					window.location.href = 'https://' + message.domain
				}
			});
		}
	}
}
</script>
