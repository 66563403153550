<template>
		<div class="login d-flex flex-column align-items-center h-80">
			<a href="/">
				<img class="logo" src="/images/logo.png" alt="" width="122" height="85">				
			</a>

			<template>
				<div class="build">
					<h2 class="auth-text text-center">Can’t find your workspace?</h2>
					<p class="text-center login-intro">Please enter your email address</p>

					<validation-observer ref="observer" v-slot="{ handleSubmit }">
						<form class="w-100" @submit.stop.prevent="handleSubmit(onSubmit)">
							<div class="mb-3">
								<ValidationProvider rules="required|email" v-slot="{ errors }">
									<input type="text" class="form-control " id="email" placeholder="email" v-model="email" :class="{'is-danger': errors[0]}">
									<span class="invalid-feedback d-inline-block" v-show="errors">{{ errors[0] }}</span>
								</ValidationProvider>
							</div>
							<button @click.prevent="next()" class="btn btn-secondary w-100 text-white">Next Step</button>
						</form>
					</validation-observer>

					<div class="d-flex flex-column align-items-center justify-content-center">
						<p class="login-text mb-2">Know your workspace URL?</p>
						<a href="/login" class="login-link">Login to your workspace</a>

						<p class="login-text mt-5 mb-2">Looking to create a workspace instead?</p>
						<a href="/register" class="login-link">Sign up here</a>
					</div>
				</div>
			

			
			</template> 

		</div>
</template>
<script>
export default{
	data() {
		return{
			email:'',
		}
	},
	methods:{
   }  
}
</script>
<style scoped>
	.is-danger{
		border: 1px solid #e3342f !important;
	}
</style>


