<template>
	<input id="phone_number" ref="phoneNumber" :value="value" autocomplete="tel" class="form-control" name="phone_number" placeholder="Phone Number" type="tel">
</template>

<script>
import intlTelInput from 'intl-tel-input'

export default {
	name: "PhoneNumber",

	props: {
		value: String
	},

	mounted: function () {
		let ip_data_key = document.head.querySelector('meta[name="ip_data_key"]').content
		this.iti = intlTelInput(this.$refs.phoneNumber, {
			utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/15.0.1/js/utils.js?1549804213570',
			preferredCountries: ['gb'],
			autoPlaceholder: 'off',
			initialCountry: 'auto',
			geoIpLookup: function (callback) {
				$.get(`https://api.ipdata.co/?api-key=${ip_data_key}`, () => {
				}, 'jsonp').always(response => {
					let countryCode = (response && response.country_code) ? response.country_code : ''
					callback(countryCode)
				})
			}
		})
	}
}
</script>

<style scoped>

</style>