<template>
    <div class="login d-flex flex-column align-items-center h-80">
        <a href="/">
            <svg class="mb-2 mt-5" height="85" width="122">
                <use xlink:href="#symbol-logo-darker"></use>
            </svg>
        </a>

        <div class="build">
            <h2 class="auth-text text-center">Sign in to your workspace.</h2>
            <p class="text-center login-intro">Please enter your workspace’s MyKii URL.</p>

            <validationObserver ref="observer" v-slot="{ handleSubmit }">
                <form autocomplete="off" class="w-100" @submit.prevent="handleSubmit(); submitForm();">
                    <div class="mb-3">
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <div class="input-group">
                                <input v-model="data.domain" :class="{'is-danger': errors[0]}" aria-describedby="basic-addon2" aria-label="Company domain"
                                       class="form-control" name="domain" placeholder="Your domain name" type="text" @keydown="serverErrors = []">
                                <span id="company_domain" class="input-group-text">.{{ domain }}</span>
                            </div>
                            <p v-show="errors[0] || serverErrors.length > 0" class="invalid-feedback d-inline-block">
                                <span v-if="errors[0]">{{ errors[0] }}</span>
                                <span v-if="serverErrors.domain">{{ serverErrors.domain[0] }}</span>
                            </p>
                        </ValidationProvider>
                    </div>
                    <button class="btn btn-secondary w-100 text-white" type="submit">Continue</button>
                </form>
            </validationObserver>

            <div class="d-flex flex-column align-items-center justify-content-center">
                <!-- <p class="login-text mb-2">Don’t know your workspace URL?</p>
				<a href="/find-workspace" class="login-link">Find your workspace</a> -->

                <p class="login-text mb-2">Looking to create a workspace instead?</p>
                <a class="login-link" href="/register">Sign up here</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	props: {
		domain: {
			required: true
		}
	},

	data: function () {
		return {
			data: {
				domain: ''
			},
			serverErrors: [],
			publicPath: process.env.MIX_APP_URL
		}
	},

	methods: {
		submitForm: function () {
			this.$refs.observer.validate().then(async result => {
				if (result) {
					this.serverErrors = []

					try {
						const response = await axios.post('/domain/verify', this.data)
						window.location.href = response.data.domain
					} catch (error) {
						if (error.response.status === 422) {
							return this.serverErrors = error.response.data.errors
						}
					}
				}
			})
		}
	}
}
</script>

<style scoped>
.is-danger {
    border: 1px solid #e3342f !important;
}
</style>


