require('./bootstrap')
window.Vapor = require('laravel-vapor');

import Vue from 'vue'
import {extend, localize, ValidationObserver, ValidationProvider} from 'vee-validate';
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import Multiselect from 'vue-multiselect'

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
	extend(rule, rules[rule]);
});

localize("en", en);

Vue.use(VueTelInput, {
	mode: 'international'
})

Vue.mixin({
    methods: {
        asset: window.Vapor.asset
    },
});

Vue.component('register', require('./Components/Register.vue').default)
Vue.component('login', require('./Components/Login.vue').default)
Vue.component('find-workspace', require('./Components/FindWorkspace.vue').default)
Vue.component('creating-account', require('./Components/CreatingAccount.vue').default)

Vue.component("ValidationObserver", ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.component('multiselect', Multiselect)
Vue.component('phone-number', require('./Components/inputs/PhoneNumber.vue').default)

new Vue({
	el: '#app',
}).$mount('#app')
