<template>
    <div class="register-body d-flex flex-column align-items-center justify-content-around h-80">
        <a v-if="!accountCreated" href="/">
            <svg class="mb-2 mt-5" height="85" width="122">
                <use xlink:href="#symbol-logo-darker"></use>
            </svg>
        </a>

        <template v-if="!accountCreated">
            <h2 v-if="form.step === 1" class="auth-text text-center mb-3">Register your account to <br /><strong>get started.</strong></h2>
            <h2 v-else-if="form.step === 2" class="auth-text text-center mb-3">Now, set up your organisation.</h2>

            <div class="bg-white step">
                <div v-if="form.step === 1">
                    <validation-observer key="step-1" ref="stepOneObserver" tag="form" @submit.prevent="next()">
                        <p class="text-center pb-3 form-title">Your details</p>
                        <div class="mb-2">
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <input id="name" v-model="form.user.name" :class="{'is-danger': errors[0] || serverErrors['user.name']}" class="form-control"
                                       placeholder="Name" type="text">
                                <span v-if="errors.length > 0 && !serverErrors['user.name']" class="invalid-feedback d-inline-block">{{ errors[0] }}</span>
                                <span v-if="serverErrors['user.name']" class="invalid-feedback d-inline-block">{{ serverErrors['user.name'][0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="mb-2">
                            <ValidationProvider v-slot="{ errors }" rules="required|email">
                                <input id="Email" v-model="form.user.email" :class="{'is-danger': errors[0] || serverErrors['user.email']}" class="form-control"
                                       placeholder="Email" type="email">
                                <span v-if="errors.length > 0 && !serverErrors['user.email']" class="invalid-feedback d-inline-block">{{ errors[0] }}</span>
                                <span v-if="serverErrors['user.email']" class="invalid-feedback d-inline-block">{{ serverErrors['user.email'][0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="mb-2">
                            <vue-tel-input v-model="form.user.phone" :class="['form-control bg-white', {'is-danger': serverErrors.phone}]"
                                           :input-options="inputOptions"></vue-tel-input>
                            <span v-if="serverErrors['user.phone']" class="invalid-feedback d-inline-block">{{ serverErrors['user.phone'][0] }}</span>
                        </div>

                        <div class="mb-2">
                            <ValidationProvider v-slot="{ errors }" rules="required|min:8" vid="password">
                                <input id="password" v-model="form.user.password" :class="{'is-danger': errors[0] || serverErrors['user.password']}"
                                       class="form-control " placeholder="Password"
                                       type="password">
                                <span v-if="errors.length > 0 && !serverErrors['user.password']" class="invalid-feedback d-inline-block">{{ errors[0] }}</span>
                                <span v-if="serverErrors['user.password']" class="invalid-feedback d-inline-block">{{ serverErrors['user.password'][0] }}</span>
                            </ValidationProvider>
                        </div>


                        <div class="mb-4">
                            <ValidationProvider v-slot="{ errors }" name="confirm password" rules="required|confirmed:password">
                                <input id="password_confirmation" v-model="form.user.password_confirmation"
                                       :class="{'is-danger': errors[0] || serverErrors['user.password_confirmation']}" class="form-control "
                                       placeholder="Confirm Password" type="password">
                                <span v-if="errors.length > 0 && !serverErrors['user.password_confirmation']"
                                      class="invalid-feedback d-inline-block">{{ errors[0] }}</span>
                                <span v-if="serverErrors['user.password_confirmation']"
                                      class="invalid-feedback d-inline-block">{{ serverErrors['user.password_confirmation'][0] }}</span>
                            </ValidationProvider>
                        </div>

                        <button :disabled="!form.user.terms" class="btn btn-secondary w-100 text-white mb-4" type="submit">Next step</button>

                        <div class="form-check w-100">
                            <ValidationProvider v-slot="{ errors }" name="terms and conditions" rules="required">
                                <input id="terms" v-model="form.user.terms" class="form-check-input" type="checkbox">
                                <label class="form-check-label policy" for="terms">I agree to the <a href="/privacy-policy" target="_blank">privacy policy</a>
                                    and the <a href="/terms-and-conditions" target="_blank">terms and conditions</a>.</label>
                                <span v-if="errors.length > 0" class="invalid-feedback d-inline-block">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </validation-observer>
                </div>
                <div v-else-if="form.step === 2">
                    <validation-observer key="step-2" ref="stepTwoObserver" tag="form" @submit.prevent="next()">
                        <p class="text-center pb-3 form-title">Enter your company details</p>
                        <div class="mb-2">
                            <ValidationProvider v-slot="{ errors }" name="company name" rules="required|min:5">
                                <input id="company_name" v-model="form.partner.company_name"
                                       :class="{'is-danger': errors[0] || serverErrors['partner.company_name']}" class="form-control" placeholder="Company Name"
                                       type="text">
                                <span v-if="errors.length > 0 && !serverErrors['partner.company_name']" class="invalid-feedback d-inline-block">
                                    {{ errors[0] }}</span>
                                <span v-if="serverErrors['partner.company_name']"
                                      class="invalid-feedback d-inline-block">{{ serverErrors['partner.company_name'][0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="mb-2">
                            <ValidationProvider v-slot="{ errors }" name="company email" rules="required|email">
                                <input id="company_email" v-model="form.partner.company_email"
                                       :class="{'is-danger': errors[0] || serverErrors['partner.company_email']}" class="form-control"
                                       placeholder="Company Email"
                                       type="email">
                                <span v-if="errors.length > 0 && !serverErrors['partner.company_email']" class="invalid-feedback d-inline-block">
                                    {{ errors[0] }}</span>
                                <span v-if="serverErrors['partner.company_email']"
                                      class="invalid-feedback d-inline-block">{{ serverErrors['partner.company_email'][0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="mb-2">
                            <ValidationProvider v-slot="{ errors }" name="company phone" rules="required">
                                <vue-tel-input v-model.trim="form.partner.company_phone"
                                               :class="['form-control bg-white', {'is-danger': serverErrors.company_phone}]"
                                               :input-options="inputOptions"></vue-tel-input>
                                <span v-if="serverErrors['partner.company_phone']"
                                      class="invalid-feedback d-inline-block">{{ serverErrors['partner.company_phone'][0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="mb-2">
                            <ValidationProvider v-slot="{ errors }" name="company domain" rules="required">
                                <div class="input-group">
                                    <input v-model="form.partner.domain" :class="{'is-danger': errors[0] || serverErrors['partner.domain']}"
                                           aria-describedby="basic-addon2" aria-label="domain" class="form-control" placeholder="yourcompany" type="text">
                                    <span id="domain" class="input-group-text">.{{ domain }}</span>
                                </div>
                                <span v-if="errors.length > 0 && !serverErrors['partner.domain']" class="invalid-feedback d-inline-block">{{ errors[0] }}</span>
                                <span v-if="serverErrors['partner.domain']" class="invalid-feedback d-inline-block">
                                    {{ serverErrors['partner.domain'][0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="mb-2">
                            <ValidationProvider v-slot="{ errors }" name="address" rules="required|min:5">
                                <input id="address_1" v-model="form.partner.address_1" :class="{'is-danger': errors[0] || serverErrors['partner.address_1']}"
                                       class="form-control" placeholder="Address Line 1"
                                       type="text">
                                <span v-if="errors.length > 0 && !serverErrors['partner.address_1']" class="invalid-feedback d-inline-block">
                                    {{ errors[0] }}</span>
                                <span v-if="serverErrors['partner.address_1']" class="invalid-feedback d-inline-block">
                                    {{ serverErrors['partner.address_1'][0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="mb-2 d-flex justify-content-between select-input">
                            <ValidationProvider v-slot="{ errors }" name="address" rules="">
                                <input id="address_2" v-model="form.partner.address_2" :class="{'is-danger': errors[0] || serverErrors['partner.address_2']}"
                                       class="form-control" placeholder="Address Line 2" type="text">
                                <span v-if="errors.length > 0 && !serverErrors['partner.address_2']" class="invalid-feedback d-inline-block">
                                    {{ errors[0] }}</span>
                                <span v-if="serverErrors['partner.address_2']" class="invalid-feedback d-inline-block">
                                    {{ serverErrors['partner.address_2'][0] }}</span>
                            </ValidationProvider>

                            <ValidationProvider v-slot="{ errors }" class="ml-3 w-49" name="postal code" rules="required">
                                <input id="postal_code" v-model="form.partner.postal_code"
                                       :class="{'is-danger': errors[0] || serverErrors['partner.postal_code']}" class="form-control" placeholder="Post Code"
                                       type="text">
                                <span v-if="errors.length > 0 && !serverErrors['partner.postal_code']" class="invalid-feedback d-inline-block">
                                    {{ errors[0] }}</span>
                                <span v-if="serverErrors['partner.postal_code']"
                                      class="invalid-feedback d-inline-block">{{ serverErrors['partner.postal_code'][0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="mb-2">
                            <ValidationProvider v-slot="{ errors }" class="mr-3 w-49" name="country" rules="required">
                                <multiselect
                                        id="country"
                                        v-model="form.partner.country"
                                        :allow-empty="true"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :hide-selected="false"
                                        :internal-search="true"
                                        :limit="20"
                                        :loading="countryLoader"
                                        :max-height="600"
                                        :options="countries"
                                        :options-limit="50"
                                        :searchable="true"
                                        :show-no-results="true"
                                        label="name"
                                        name="country"
                                        open-direction="bottom"
                                        openDirection="top"
                                        placeholder="Search countries"
                                        track-by="id"
                                        @open="searchCountryIfEmpty()"
                                        @search-change="searchCountry">
										<span slot="noResult" class="text-center">
											<span class="d-block">Oops! No countries found.</span>
											<span class="d-block">Consider changing the search query.</span>
										</span>
                                </multiselect>

                                <span v-if="errors.length > 0 && !serverErrors['partner.country']" class="invalid-feedback d-inline-block">
                                    {{ errors[0] }}</span>
                                <span v-if="serverErrors['partner.country']" class="invalid-feedback d-inline-block">
                                    {{ serverErrors['partner.country'][0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="mb-2">
                            <ValidationProvider v-slot="{ errors }" class="mr-3 w-49" name="timezone" rules="required">
                                <multiselect
                                        v-model="form.partner.timezone"
                                        :allow-empty="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :hide-selected="false"
                                        :internal-search="true"
                                        :loading="timezoneLoader"
                                        :max-height="600"
                                        :options="timezones"
                                        :options-limit="50"
                                        :searchable="true"
                                        :show-no-results="true"
                                        label="name"
                                        name="timezone"
                                        placeholder="Search timezones" track-by="zone"
                                        @open="searchTimezoneIfEmpty()">
									<span slot="noResult" class="text-center">
										<span class="d-block">Oops! No timezones found.</span>
										<span class="d-block">Consider changing the search query.</span>
									</span>
                                </multiselect>
                                <span v-if="errors.length > 0 && !serverErrors['partner.timezone']" class="invalid-feedback d-inline-block">
                                    {{ errors[0] }}</span>
                                <span v-if="serverErrors['partner.timezone']" class="invalid-feedback d-inline-block">
                                    {{ serverErrors['partner.timezone'][0] }}</span>
                            </ValidationProvider>
                        </div>
                        <button :disabled="formLoading" class="btn btn-secondary w-100 text-white" type="submit">
                            <svg v-if="formLoading" class="me-2" height="25" stroke="#fff" viewBox="0 0 38 38" width="25" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fill-rule="evenodd">
                                    <g stroke-width="2" transform="translate(1 1)">
                                        <circle cx="18" cy="18" r="18" stroke-opacity=".5"/>
                                        <path d="M36 18c0-9.94-8.06-18-18-18">
                                            <animateTransform
                                                    attributeName="transform"
                                                    dur="1s"
                                                    from="0 18 18"
                                                    repeatCount="indefinite"
                                                    to="360 18 18"
                                                    type="rotate"/>
                                        </path>
                                    </g>
                                </g>
                            </svg>
                            Start my free trial
                        </button>
                    </validation-observer>
                </div>
                <div class="mb-2 mt-4 w-100 d-flex align-items-center justify-content-center">
                    <span :class="{ 'active' : form.step === 1}" class="indicator" @click="form.step > 1 ? form.step = 1 : null"></span>
                    <span :class="{ 'active' : form.step === 2}" class="indicator"></span>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="build d-flex flex-column justify-content-center align-items-center">
                <a href="/">
                    <svg class="mb-2 mt-5" height="85" width="122">
                        <use xlink:href="#symbol-logo-darker"></use>
                    </svg>
                </a>
                <h2 class="auth-text text-center">Check your email, your almost there.</h2>
                <p class="text-center build-title">We have sent you an email with a link to complete your account set up.</p>
            </div>
        </template>
    </div>
</template>
<script>
export default {
	props: {
        domain: {
			required: true
		}
	},

	data () {
		return {
			form: {
				user: {
					name: null,
					email: null,
					phone: null,
					password: null,
					password_confirmation: null,
					terms: false,
				},

				partner: {
					company_name: null,
					company_email: null,
					company_phone: null,
					domain: null,
					address_1: null,
					address_2: null,
					country: null,
					timezone: null,
					postal_code: null,
				},
				step: 1,
			},
			inputOptions: {
				placeholder: 'Enter a phone number',
				id: 'phone',
				name: 'phone',
				required: true
			},
			serverErrors: [],
			countries: [],
			countryLoader: false,
			timezones: [],
			timezoneLoader: false,
			formLoading: false,
			accountCreated: false
		}
	},

	methods: {
		registerPartner () {
			this.formLoading = true
			axios.post('/register-partner', this.form).then(response => {
				this.accountCreated = true
			}).catch(error => {
				this.formLoading = false
				this.serverErrors = error.response.data.errors
			})
		},

		serverValidation () {
			this.form.user.phone = this.form.user.phone.replace(/\s+/g, '')
			this.serverErrors = []
			axios.post('/register-partner/validation', this.form).then(response => {
				if (this.form.step === 2) {
					this.registerPartner()
				} else this.form.step++;
			}).catch(error => {
				this.serverErrors = error.response.data.errors
			})
		},

		prev () {
			return this.form.step--;
		},

		async next () {
			let stepIsValid = true
			if (this.form.step === 1) {
				stepIsValid = await this.$refs.stepOneObserver.validate();
			} else if (this.form.step === 2) {
				stepIsValid = await this.$refs.stepTwoObserver.validate();
			}

			if (!stepIsValid) {
				return
			}

			this.serverValidation()
		},

		searchCountry (query) {
			this.countryLoader = true
			axios
				.get('/helpers/search-countries?search=' + query)
				.then(response => {
					this.countryLoader = false
					this.countries = response.data
				})
				.catch(error => {
					this.countryLoader = false
				})
		},

		searchCountryIfEmpty () {
			if (this.countries.length < 1) {
				this.searchCountry('')
			}
		},

		searchTimezone () {
			this.timezoneLoader = true
			axios
				.get('/helpers/search-timezones')
				.then(response => {
					this.timezoneLoader = false
					this.timezones = Object.values(response.data)
				})
				.catch(error => {
					this.timezoneLoader = false
				})
		},

		searchTimezoneIfEmpty () {
			if (this.timezones.length < 1) {
				this.searchTimezone()
			}
		}
	}
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


